import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./section.scss";

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section section-growing-hormone-indication"
          data-image-src=""
          id="growing-hormone-indication"
        >
          <div className="bg-growing-hormone-indication growing-hormone-indication-half">
            <div className="display-table">
              <div className="display-table-cell">
                <Container>
                  <Row className="justify-content-center">
                    <Col md={12} lg={7} className="section-growing-hormone-indication-description text-white">
                      <h1 className="growing-hormone-indication-title">
                        Indicações de uso<br/>
                        hormônio do crescimento
                      </h1>
                    </Col>
                    <Col className="content-height" md={12} lg={6}></Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}
