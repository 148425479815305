import React, { Component } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button
} from "reactstrap";

import "./navbar.scss";
import NavbarStrip from "./NavbarStrip";

export default class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      navClass : "",
      navItems: [
        { id: 2, idnm: "/program", navheading: "Conheça o programa" },
        { id: 3, idnm: "/growingHormone", navheading: "Hormônio do crescimento" },
        { id: 4, idnm: "/cristalia", navheading: "Conheça o Cristália" },
        { id: 5, idnm: "/applicator", navheading: "Aplicador" },
        { id: 6, idnm: "/#accredited-pharmacy", navheading: "Onde comprar" }
      ],
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      this.setState({ navClass : "is-sticky"});
    } else {
      this.setState({ navClass : ""});
    }
  }

  render() {
    return (
      <React.Fragment>
          <div id="navbar" className={this.state.navClass}>
            <NavbarStrip />
            <nav className="navbar navbar-expand-lg navbar-white fixed-top navbar-custom">
              <Container fluid={true}>
                <NavbarBrand className="logo" href="/">
                  <i className="logo__img"></i>
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar
                >
                  <Nav navbar className="navbar-end ms-auto" id="mySidenav">
                    {this.state.navItems.map((item, key) => (
                      <NavItem key={key}>
                        <NavLink href={item.idnm} className={item.idnm === window.location.pathname ? "active" : ""}>
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <div className="nav-button">
                    <Nav navbar className="navbar-end">
                      <NavLink href={"#contact"}>
                        <Button
                          color="none"
                          type="button"
                          className="btn btn-outline-primary navbar-btn btn-rounded"
                        >
                          Cadastro
                        </Button>
                      </NavLink>
                    </Nav>
                  </div>
                </Collapse>
              </Container>
            </nav>
          </div>
      </React.Fragment>
    );
  }
}
