import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import './callcenter.scss';

export default class CallCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to: this.props.buttonTo || "/program",
      content: this.props.buttonText || "Conheça mais sobre o programa"
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section section-lg" id="section-callcenter">
          <div className="bg-callcenter"></div>
          <div className="container-float">
            <Col md={12} lg={6} className="card-info">
              <h1 className="callcenter-title">
                Central de atendimento com profissionais de saúde
              </h1>
              <div className="section-title-border mt-4 bg-warning"></div>
              <p className="section-describe-callcenter pt-4">
                Nossa Central de Atendimento é formado por um time de enfermagem especializado
                e habilitado e está a sua disposição de segunda a sexta-feira, das <b>8h às 20h</b>,
                para informar sobre o manuseio do medicamento conforme prescrição médica, além das
                melhores práticas de transporte e conservação. Todo o contato pode ser realizado
                por vídeoconferência ou telefone.
              </p>
            </Col>
          </div>
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }} className="mt-5 text-center">
                <Link
                  to={this.state.to}
                  className="btn btn-purple btn-rounded btn-know-more"
                  onClick={this.props.handleCustom}
                >
                  {this.state.content}
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
