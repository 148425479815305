import axios from 'axios';
import Cookies from 'universal-cookie';
import { tokenValidate } from '../util/tokenValidate';

// eslint-disable-next-line no-unused-vars
let isRefreshing = false;
let failedRequestsQueue = [];
var cookies = new Cookies();


const api = axios.create({
  baseURL: window.cristaliaService.api,
  headers: {
    Authorization: `Bearer ${cookies.get('epharma-cristalia.token')}`,
  },
});

api.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${cookies.get('epharma-cristalia.token')}`;
  return request;
});

api.interceptors.response.use(res => {
  return res;
}, (error) => {
  if (error.response?.status === 401) {
    const { 'epharma-cristalia.token': token } = cookies;
    // toda a configuração da rota original para ser montada uma nova chamada
    const originalConfig = error.config;
    if (!tokenValidate(token)) {
      isRefreshing = true;
      api.get('/Authentication/api/v1/OAuth/Authenticate',
        {
          headers: {
            'client_id': window.cristaliaService.clientId,
            'username': window.cristaliaService.usernameAuth,
            'password': window.cristaliaService.passwordAuth 
          }
        }
      ).then(response => {
        if (response.status === 200) {
          const { token } = response.data.data;
          cookies.set('epharma-cristalia.token', token.accessToken, {
            maxAge: 60 * 60,
            path: '/'
          });

          api.defaults.headers.common['Authorization'] = `Bearer ${token.accessToken}`;
          originalConfig.headers.Authorization = `Bearer ${token.accessToken}`;

          failedRequestsQueue.forEach(request => request.onSucess());
          failedRequestsQueue = [];
        }
      }).catch(err => {
        failedRequestsQueue.forEach(request => request.onFailure(err));
        failedRequestsQueue = [];
      }).finally(() => {
        isRefreshing = false;
      });
    }

    return new Promise((resolve, reject) => {
      failedRequestsQueue.push({
        onSucess: () => {
          resolve(api(originalConfig));
        },
        onFailure: (err) => {
          reject(err)
        }
      })
    })
  }

  return Promise.reject(error)
})

export default api;