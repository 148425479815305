import React, { Component } from "react";
import { Row, Input, FormGroup, Form, Label, Col } from "reactstrap";
import InputMask from 'react-input-mask';
import * as yup from "yup";
import { Formik, ErrorMessage, Field } from "formik";
import { validateCPF } from "validations-br";
import api from "../../services/api";
import Consent from "../Regulations&Consent/Consent";
import Regulations from "../Regulations&Consent/Regulations";

import Swal from 'sweetalert2';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Nome é obrigatório')
    .min(8, 'Nome precisa ter no mínimo 8 caracteres'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .min(11, 'CPF precisa ter no mínimo 11 dígitos')
    .max(14, 'CPF precisa ter no máximo 14 caracteres')
    .test(
      'test-invalid-cpf',
      'Insira um CPF válido',
      (value) => validateCPF(value)
    ),
    birthdate: yup
    .date()
    .required('Data de nascimento é obrigatório'),
  regulations: yup
    .bool()
    .oneOf([true], 'Para prosseguir é necessário estar de acordo com o Regulamento e Política de Privacidade'),
  consent: yup
    .bool()
    .oneOf([true], 'Para prosseguir é necessário estar de acordo com o Termo de Consentimento para dados sensíveis')
});

export default class FormPersonal extends Component {
  constructor(props) {
    super(props);

    this.formikProps = {
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: async (values, {resetForm}) => {
        const {data} = await api.get(`/Beneficiary/api/v1/Beneficiary/Dynamic/${ window.cristaliaService.benefitId}/${values.cpf.replace(/\D/g, '')}`);

        if (data.success) {
          Swal.fire({
            icon: 'warning',
            title: 'Oops',
            text: 'Este beneficiário já possui cadastro!',
            confirmButtonColor: 'var(--bs-warning)'
          });
          return;
        }
        resetForm({ values: undefined });
        return this.continue(new Event(undefined));
      }
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    return (
      <React.Fragment>
        <Formik
          {...this.formikProps}
          initialValues={{
            name: values.name,
            cpf: values.cpf,
            birthdate: values.birthdate,
            regulations: values.regulations,
            consent: values.consent
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form className="contact-form" onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <FormGroup floating>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="off"
                    placeholder="Nome do paciente"
                    onChange={e => {
                      handleChange(e);
                      setFieldValue('name', e.target.value);
                    }}
                    invalid={errors.name && touched.name}
                  />
                  <Label for="name">Nome do paciente</Label>
                  <ErrorMessage name="name" component="div" className="invalid-feedback" />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup floating>
                  <InputMask
                    id="cpf"
                    name="cpf"
                    type="text"
                    mask="999.999.999-99"
                    autoComplete="off"
                    placeholder="CPF do paciente"
                    className={
                      "form-control" +
                      (errors.cpf && touched.cpf
                        ? " is-invalid"
                        : ""
                      )
                    }
                    onChange={e => {
                      handleChange(e);
                      setFieldValue('cpf', e.target.value)
                    }}
                  >
                  </InputMask>
                  <Label for="cpf">CPF do paciente</Label>
                  <ErrorMessage name="cpf" component="div" className="invalid-feedback" />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup floating>
                  <Input
                    id="birthdate"
                    name="birthdate"
                    type="date"
                    autoComplete="off"
                    placeholder="Data de nascimento"
                    className={
                      "form-control" +
                      (errors.birthdate && touched.birthdate
                        ? " is-invalid"
                        : ""
                      )
                    }
                    onChange={e => {
                      handleChange(e);
                      setFieldValue('birthdate', e.target.value);
                    }}
                    invalid={errors.birthdate && touched.birthdate}
                  />
                  <Label for="birthdate">Data de nascimento</Label>
                  <ErrorMessage name="birthdate" component="div" className="invalid-feedback" />
                </FormGroup>
              </Row>
              <Row>
                <Col lg={12} className="text-start">
                  <FormGroup check>
                    <Field
                      id="regulations"
                      name="regulations"
                      type="checkbox"
                      checked={values.regulations}
                      onChange={e=>{
                        handleChange({name: 'regulations', value: !values.regulations});
                        setFieldValue('regulations', !values.regulations);
                      }}
                      className={
                        "form-check-input" +
                        (errors.regulations && touched.regulations
                          ? " is-invalid"
                          : ""
                        )
                      }
                    />
                    <Label check for="regulations">Li e concordo com o <Regulations /> do Programa Cristália Saúde</Label>
                    <ErrorMessage name="regulations" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup check>
                    <Field
                      id="consent"
                      name="consent"
                      type="checkbox"
                      checked={values.consent}
                      onChange={e=>{
                        handleChange({name: 'consent', value: !values.consent});
                        setFieldValue('consent', !values.consent);
                      }}
                      className={
                        "form-check-input" +
                        (errors.consent && touched.consent
                          ? " is-invalid"
                          : ""
                        )
                      }
                    />
                    <Label check for="consent">Li e concordo com o <Consent /> do Programa Cristália Saúde</Label>
                    <ErrorMessage name="consent" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="text-start">
                  <button type="submit" id="submit" disabled={isSubmitting} className="submitBnt btn btn-purple btn-rounded mt-4">
                    {
                      isSubmitting
                        ? (<span className="spinner-border text-light spinner-border-sm" style={{marginRight: '.5rem'}} role="status" aria-hidden={isSubmitting}></span>)
                        : null
                    }
                    Prosseguir
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
