import React, { Component } from "react";
import { Container, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default class NavbarStrip extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="navbar-strip fixed-top">
          <Container fluid={true}>
            <Col lg={12} className="small-navbar d-flex align-items-center">
              <Link target="_blank" to="https://www.cristalia.com.br/">Site Cristália</Link>
              <Link to='mailto:contato@cristaliasaude.com.br'>contato@cristaliasaude.com.br</Link>
              <Link to='tel:08000006565'>0800 000 6565</Link>
            </Col>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
