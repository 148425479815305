import React, { Component } from "react";

import Section from "./section";
import NavbarPage from "../../components/Navbar/Navbar";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/footer";
import Crispen from "../../components/Crispen/Crispen";

import scrollTo from "../../util/scrollTo";

export default class Applicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "",
    };
  }

  componentDidMount() {
    scrollTo();
  }

  render() {
    return (
      <React.Fragment>
        <NavbarPage
          navClass={this.state.navClass}
        />

        <Section />

        <Crispen />

        <Contact />

        <Footer />
      </React.Fragment>
    );
  }
}
