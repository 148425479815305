import React, { Component } from "react";
import { Link } from 'react-router-dom';

import "./notFound.scss";

export default class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="wscn-http404-container">
          <div className="wscn-http404">
            <div className="pic-404">
              <img className="pic-404__parent" src="/assets/images/404/404.png" alt="404" />
              <img className="pic-404__child left" src="/assets/images/404/404_cloud.png" alt="404" />
              <img className="pic-404__child mid" src="/assets/images/404/404_cloud.png" alt="404" />
              <img className="pic-404__child right" src="/assets/images/404/404_cloud.png" alt="404" />
            </div>
            <div className="bullshit">
              <div className="bullshit__oops">OOPS!</div>
              <div className="bullshit__info">
                Todos os direitos reservados&nbsp;
                <Link style={{color: "#20a0ff"}} href="http://cristalia.com.br/" target="_blank">Cristália</Link>
              </div>
              <div className="bullshit__headline">Está página não existe...</div>
              <div className="bullshit__info">Verifique se o URL digitado está correto ou clique no botão abaixo para retornar à página anterior.</div>
              <Link to={-1} className="bullshit__return-home">Voltar</Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
