import React, { Component } from "react";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";
import "./App.scss";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          {routes.map((route, key) => (
            <Route exact path={route.path} element={route.component} key={key} />
          ))}
        </Routes>
      </React.Fragment>
    );
  }
}
