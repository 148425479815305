import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

import AccreditedPharmacySkeleton from "./AccreditedPharmacySkeleton";

export default class SearchAccreditedPharmacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }

  componentDidMount() {
    this.setState({
      list: this.props.stores
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stores !== state.stores) {
      return {
        list: props.stores,
      };
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.list.length <= 0 && this.props.loading
          ? <AccreditedPharmacySkeleton />
          : (
            this.state.list.map((store, key) => {
              return (
                <Col xs={9} sm={9} md={9} lg={4} key={key}>
                  <div className="accredited-pharmacy-box text-center mt-4 hover-effect">
                    <h1>{store.corporateName}</h1>

                    <div className="accredited-pharmacy-box__description">
                      <p>
                        <Link className="text-purple" to={'tel:' + store.corporatePhoneNumber}>{store.corporatePhoneNumber}</Link><br/>
                        <Link target="_blank" to={'https://' + store.site}>{store.site}</Link>
                      </p>
                    </div>

                    <div className="accredited-pharmacy-box__footer">
                      Abrangência: {store.coverage}
                    </div>
                  </div>
                </Col>
              )
            })
          )
        }
      </React.Fragment>
    );
  }
}

