import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./cristaliaInformation.scss";

import InformartionBox from "./InformationBox";
import LaboratoryBox from "./LaboratoryBox";

export default class CristaliaInformation extends Component {
  constructor() {
    super();
    this.state = {
      laboratory: [
        {
          image: '/assets/images/icons/cristalia.png',
          imageDescription: 'whatsapp qrcode',
          title: 'Mais de 50 anos de história¹',
          descriptions: [
            'Desde 1972, está construindo sua história junto com os brasileiros.',
            'O Laboratório Cristália é uma empresa 100% nacional que tem como missão colaborar com a melhoria das condições de tratamento da saúde da população, produzindo medicamentos com qualidade e preço justo.'
          ]
        },
        {
          image: '/assets/images/icons/dna.png',
          imageDescription: 'fones de ouvido para ligar para call center',
          title: "Pioneirismo em medicamentos biológicos¹'²",
          descriptions: [
            'O Cristália possui a primeira planta privada de Biotecnologia do Brasil.',
            'A construção da planta Industrial de Biotecnologia permitiu a produção, pela primeira vez no País, do Hormônio do Crescimento Humano (somatropina).',
            'Já na planta de Biotecnologia Anaeróbicos é produzida a enzima colagenase animal-free.'
          ],
        },
        {
          image: '/assets/images/icons/fabrica.png',
          imageDescription: 'cesta de compras',
          title: "Complexo industrial¹",
          descriptions: [
            'Possui 14 plantas industriais sendo uma delas em Buenos Aires, Argentina.',
            'Atualmente, com forte presença na América Central, América Andina e Cone Sul, o Cristália continua em plena expansão na América Latina com projetos para Chile, Peru, Colômbia e México.'
          ],
        },
      ],
      informations1: [
        {
          large: true,
          icon: "/assets/images/icons/molecula.svg",
          iconDesc: "Moléculas",
          desc: "Maior produtor de IFAs (Insumos Farmacêuticos Ativos) da América Latina¹",
        },
        {
          large: true,
          icon: "/assets/images/icons/agulha.svg",
          iconDesc: "Agulha",
          desc: "Lider em Anestesia presente em 95% dos hospitais do Brasil¹",
        },
      ],
      informations2: [
        {
          large: true,
          icon: "/assets/images/icons/cracha.svg",
          iconDesc: "Crachá de funcionário",
          desc: "+ 5.600 colaboradores que compartilham a visão de trabalhar em prol da saúde, permitindo o acesso de milhares de brasileiros a medicamentos de ponta",
        }
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-white bg-laboratory-information"
          id="laboratory-information"
          style={{
            position: 'relative',
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row className="mt-4">
                  <LaboratoryBox registers={this.state.laboratory} />
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="section bg-cristalia-information"
          id="cristalia-information"
          style={{
            position: 'relative',
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row className="mt-4">
                  <InformartionBox items={this.state.informations1} />
                </Row>

                <Row className="mt-4 justify-content-center">
                  <InformartionBox items={this.state.informations2} />
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <div className="justify-content-center footer-cristalia-information">
                  <p>Fonte:</p>
                  <ol>
                    <li><Link target="_blank" to="https://www.cristalia.com.br/historia">Site Cristalia</Link> (Acessado em 31/01/2023)</li>
                    <li>
                      Conforme informações disponíveis para consulta&nbsp;
                      <Link target="_blank" to="https://sindusfarma.org.br/noticias/empresas-foco/exibir/8131-anvisa-aprova-o-primeiro-biossimilar-100-brasileiro-do-hormonio-do-crescimento-humano#:~:text=O%20Laborat%C3%B3rio%20Crist%C3%A1lia%20anuncia%20mais,do%20Crescimento%20Humano%2C%20ou%20somatropina">
                        no banco de dados ANVISA
                      </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
