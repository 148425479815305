import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./section.scss";

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section section-program"
          data-image-src=""
          id="program"
        >
          <div className="bg-program program-half">
            <div className="display-table">
              <div className="display-table-cell">
                <Container>
                  <Row className="justify-content-center">
                    <Col md={12} lg={6} className="section-program-description text-white">
                      <h1 className="program-title">
                        Nosso<br/>
                        programa
                      </h1>
                    </Col>
                    <Col className="content-height" md={12} lg={6}></Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
