import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./contact.scss";

import SectionTitle from "../common/SectionTitle";
import ContactBox from "./ContactBox";
import FormPersonal from "./FormPersonal";
import FormAdditional from "./FormAdditional";
import api from "../../services/api";

import scrollTo from "../../util/scrollTo";
import isMinor from "../../util/isMinor";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registers: [
        {
          image: '/assets/images/icons/whatsapp-qrcode.png',
          imageDescription: 'whatsapp qrcode',
          linkTo: 'https://wa.me/5511933312390?text=Olá!%20Tenho%20interesse%20em%20me%20cadastrar%20no%20Programa%20Cristália%20Saúde',
          linkTarget: '_blank',
          title: '(11) 93331-2390',
          preDescription: 'WhatsApp',
          description: 'Escaneie o código acima para iniciar o chat via WhatsApp e realizar seu cadastro.',
          footerDescription: 'Clique aqui para abrir o chat'
        },
        {
          image: '/assets/images/icons/headset.png',
          imageDescription: 'fones de ouvido para ligar para call center',
          linkTo: 'tel:08000006565',
          linkTarget: '_self',
          title: "0800 000 6565",
          preDescription: 'Ligue agora mesmo no',
          description: 'e realize o seu cadastro com um de nossos atendentes.',
          footerDescription: 'Atendimento de segunda a sexta das 8h às 20h'
        },
        {
          image: '/assets/images/icons/basket.png',
          imageDescription: 'cesta de compras',
          handleCustom: this.handleCustom,
          linkTo: '/#accredited-pharmacy',
          linkTarget: '_self',
          title: "Balcão da farmácia",
          description: 'Se preferir você pode realizar seu cadastro direto em uma das farmácias credenciadas.',
          footerDescription: 'Consulte aqui a rede credenciada'
        },
      ],
      step: 1,
      initialValues: {
        name: '',
        cpf: '',
        birthdate: '',
        regulations: false,
        consent: false,
        medicine: 'CRISCY® REFIL CANETA',
        appliedDose: 'Não informado',
        gender: '',
        crm: '',
        crmState: '',
        crmName: '',
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        email: '',
        cellphone: '',
        responsibleName: '',
        responsibleCpf: '',
        responsibleBirthdate: '',
        degreeOfKinship: '',
        contact: false,
        isMinor: false
      },
      configuration: {}
    };
  }

  // Proceed to next step
  nextStep = async () => {
    const { step } = this.state;
    let nextStep = step + 1;

    if (nextStep === 2) {
      const {data} = await api.get(`/Client/api/v1/Benefit/Beneficiary/Register/Configuration/${window.cristaliaService.benefitId}`);

      if (data.success) {
        this.setState({
          step: nextStep,
          configuration: data.data
        });

        return;
      }
    }

    if (nextStep >= 3) {
      this.setState({ step: 1 });
    } else {
      this.setState({ step: nextStep });
    }
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = (e) => {
    // Overload it was necessary because of checkbox behavior (set value to state)
    let name, value = undefined;
    if (e.hasOwnProperty('target')) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = e.name;
      value = e.value;
    }

    switch(name) {
      case 'birthdate':
        const birthdate = new Date(`${value}T00:00:00`); 
        this.setState(preState => ({
          initialValues: {
            ...preState.initialValues,
            isMinor: !isMinor(birthdate),
            [name]: value
          }
        }));
        break;
      default:
        this.setState(preState => ({
          initialValues: {
            ...preState.initialValues,
            [name]: value
          }
        }));
        break;
    }
  };

  bindCep = (e) => {
    this.setState(preState => ({
      initialValues: {
        ...preState.initialValues,
        address: e.addressLine,
        city: e.city.name,
        neighborhood: e.neighborhood,
        state: e.city.state.stateCode,
      }
    }));
  }

  bindCrm = (value) => {
    this.setState(preState => ({
      initialValues: {
        ...preState.initialValues,
        crmName: value
      }
    }));
  }

  handleCustom = (e) => {
    scrollTo(e.target.hash);
  }

  renderForm() {
    switch (this.state.step) {
      case 1:
        return (
          <FormPersonal
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={this.state.initialValues}
          />
        );
      case 2:
        return (
          <FormAdditional
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={this.state.initialValues}
            configuration={this.state.configuration}
            bindCep={this.bindCep}
            bindCrm={this.bindCrm}
          />
        );
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-contact section-contact"
          id="contact"
          style={{
            paddingBottom: '0px',
            backgroundColor: '#dde0f7',
            position: 'relative',
          }}
        >
          <Container>
            <SectionTitle
              title="Onde se cadastrar"
              desc="No Programa Cristália Saúde você tem todas as opções abaixo para realizar seu cadastro:"
            />

            <Row className="d-flex justify-content-center">
              <Col lg={9}>
                <Row className="mt-4 justify-content-center">
                  <ContactBox registers={this.state.registers} />
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <div className="text-divider">Ou</div>
            </Row>
            <Row>
              <Col className="col-9">
                <div className="pb-5">
                  {this.renderForm()}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
