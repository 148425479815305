import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./crispen.scss";

import CrispenBox from "./CrispenBox";

export default class Crispen extends Component {
  constructor() {
    super();
    this.state = {
      items1: [
        {
          large: true,
          icon: "assets/images/icons/crispen/Prancheta 1.png",
          iconDesc: "Caneta reutilizável",
          title: "Caneta reutilizável¹",
          desc: "Não é necessário realizar o preparo do tratamento todas as vezes que for feita a aplicação, basta fazer o preparo apenas uma única vez e refrigerar o conteúdo restante até 28 dias após a reconstituição.",
        },
        {
          large: true,
          icon: "assets/images/icons/crispen/Prancheta 2.png",
          iconDesc: "Seletor de dose",
          title: "Seletor de dose¹",
          desc: "Antes de aplicar a caneta, basta girar o seletor escolhendo a dose desejada e pronto. Isso garante a administração do medicamento sem erro.",
        },
      ],
      items2: [
        {
          large: false,
          icon: "assets/images/icons/crispen/Prancheta 3.png",
          iconDesc: "Emoji de rosto sorrindo",
          title: "Possui botão lateral",
        },
        {
          large: false,
          icon: "assets/images/icons/crispen/Prancheta 4.png",
          iconDesc: "Emoji de indicador apontando",
          title: "Intuitiva: fácil manuseio",
        },
        {
          large: false,
          icon: "assets/images/icons/crispen/Prancheta 5.png",
          iconDesc: "Garrafa com marcação de dose",
          title: "Dose em mg/UI",
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <section className="section bg-crispen section-crispen" id="crispen">
          <Container>
            <Row className="vertical-content">
              <Col lg={12}>
                <h1 className="section-crispen-title text-center">{""}</h1>
                <p className="section-crispen-subtitle text-muted pt-2">
                  A Crispen é uma caneta injetora que chegou para facilitar<br/>
                  <b>o tratamento de pacientes que têm deficiência ou insuficiência do hormônio do crescimento (GH)¹</b>
                </p>
              </Col>
            </Row>
            <Row className="vertical-content">
              <Col lg={12}>
                <Row className="mt-4 justify-content-center">
                  <CrispenBox items={this.state.items1} />
                </Row>

                <Row className="mt-4 justify-content-center">
                  <CrispenBox items={this.state.items2} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <div className="crispen-package">
                  <img src="/assets/images/others/Crispen-caixa-600x600.png" alt="Pacote de itens Crispen" />
                </div>
              </Col>
              <div className="crispen-source">
                <p>Fonte:</p>
                <ol>
                  <li>Bula do medicamento</li>
                </ol>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
