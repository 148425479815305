import React, { Component } from "react";
import { Col } from "reactstrap";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default class AccreditedPharmacySkeleton extends Component {
  render() {
    return (
      <React.Fragment>
        {[1,2,3].map((item, key) => {
          return (
            <Col xs={9} sm={9} md={9} lg={4} key={key}>
              <div className="accredited-pharmacy-box text-center mt-4 hover-effect">
                <h3><Skeleton width={300} /></h3>
                <div className="accredited-pharmacy-box__description">
                  <p>
                    <Skeleton width={100} />
                    <Skeleton width={150} />
                  </p>
                </div>
              </div>
            </Col>
          )
        })}
      </React.Fragment>
    );
  }
}

