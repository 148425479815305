import React, { Component } from "react";
import { Row, Input, FormGroup, Form, Label, Col } from "reactstrap";
import * as yup from "yup";
import InputMask from 'react-input-mask';
import { Formik, ErrorMessage, Field } from "formik";
import { validateCep as validateCEP, validateCPF, validatePhone } from "validations-br";
import Regulations from "../Regulations&Consent/Regulations";

import Swal from 'sweetalert2';

import api from "../../services/api";
import states from '../../util/statesWithCodeValue.json';
import degreeOfKinship from './degreeOfKinship.json';
import isMinor from "../../util/isMinor";

const validationSchema = yup.object().shape({
  isMinor: yup
    .bool(),
  crm: yup
    .string()
    .required('CRM do médico é obrigatório'),
  crmState: yup
    .string()
    .required('CRM estado do médico é obrigatório'),
  appliedDose: yup
    .string()
    .required('Dose obrigatório')
    .matches(/^\d{1,2}\,\d{1,2}$/, 'Preencha a dose com valores de 0,01 a 99,99'),
  gender: yup
    .string()
    .required('Gênero do paciente é obrigatório'),
  cep: yup
    .string()
    .required('CEP é obrigatório')
    .min(8, 'CEP precisa ter no mínimo 8 dígitos')
    .max(9, 'CEP precisa ter no máximo 9 caracteres')
    .test(
      'test-invalid-cep',
      'Insira um CEP válido',
      (value) => validateCEP(value)
    ),
  address: yup
    .string()
    .required('Endereço é obrigatório'),
  number: yup
    .number()
    .required('Número é obrigatório'),
  neighborhood: yup
    .string()
    .required('Bairro é obrigatório'),
  city: yup
    .string()
    .required('Cidade é obrigatório'),
  state: yup
    .string()
    .required('UF é obrigatório'),
  email: yup
    .string()
    .required('E-mail é obrigatório')
    .email('Insira um e-mail válido')
    .min(10, 'E-mail precisa ter no mínimo 10 caracteres'),
  cellphone: yup
      .string()
      .required('Celular é obrigatório')
      .test(
        'test-invalid-phone',
        'Insira um número válido',
        (value) => validatePhone(value)
      ),
  responsibleName: yup
        .string()
        .when("isMinor", {
          is: true,
          then: () => yup
            .string()
            .required('Nome do responsável é obrigatório')
        }),
  responsibleCpf: yup
        .string()
        .when(
          "isMinor", {
            is: true,
            then: () => yup
              .string()
              .required('CPF do responsável é obrigatório')
              .min(11, 'CPF precisa ter no mínimo 11 dígitos')
              .max(14, 'CPF precisa ter no máximo 14 caracteres')
              .test(
                'test-invalid-cpf',
                'Insira um CPF válido',
                (value) => validateCPF(value)
              )
          }
        ),
  responsibleBirthdate: yup
        .string()
        .when("isMinor", {
          is: true,
          then: () => yup
            .string()
            .required('Data de nascimento do responsável é obrigatório')
            .test(
              'test-responsible-os-minor',
              'O responsável deve ser uma pessoa maior de 18 anos',
              (value) => isMinor(new Date(value))
            )
        }),
  degreeOfKinship: yup
        .string()
        .when(
          "isMinor", {
            is: true,
            then: () => yup
              .string()
              .required('Grau de parentesco do responsável é obrigatório')
          }
        ),
});

export default class FormAdditional extends Component {
  constructor(props) {
    super(props);
    this.address = {
      addressLine: "",
      number: null,
      complement: null,
      neighborhood: "",
      zipCode: "",
      city: {
        id: 0,
        name: "",
        zipCode: "",
        areaCode: "",
        ibgeCode: 0,
        latitude: 0,
        longitude: 0,
        stateId: 0,
        state: {
          id: 0,
          name: "",
          stateCode: "",
          hasIva: true,
          ibgeCode: 0,
          parentId: 0,
          mapId: null,
          icmsId: 0,
          lastUpdateDate: "",
          regionName: null,
          transferStatus: ""
        },
        tP_Mun: "",
        cdMunSap: 0
      }
    };

    this.state = {
      loading: {
        cep: false
      },
      disabled: {
        address: true,
        neighborhood: true
      },
      prescriber:{
        crm: '',
        stateCode: '',
        name: '',
        appliedDose: ''
      }
    };

    this.formikProps = {
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: async (values, {resetForm}) => {
        const { values: defaultValues } = this.props;
        const configuration = this.props.configuration;
        const { isMinor } = values;

        // eslint-disable-next-line array-callback-return
        var fields = configuration.fields.map((field) => {
          switch (field.apiAlias) {
            case 'beneficiario_cpf':
            case 'beneficiario_cartao_usuario':
            case 'beneficiario_cartao_titular':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: defaultValues.cpf.replace(/\D/g, '')
              }
            case 'beneficiario_nome':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: defaultValues.name
              }
            case 'beneficiario_dt_inicio':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
              }
            case 'beneficiario_endereco_cel':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.cellphone.replace(/\D/g, '')
              }
            case 'beneficiario_aceite_contato':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: Boolean(values.contact) === true ? 7 : null
              }
            case 'beneficiario_email_1':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.email
              }
            case 'beneficiario_endereco_uf':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.state
              }
            case 'beneficiario_endereco_cidade':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.city
              }
            case 'beneficiario_endereco_bairro':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.neighborhood
              }
            case 'beneficiario_endereco_complemento':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.complement
              }
            case 'beneficiario_endereco_numero':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.number
              }
            case 'beneficiario_endereco':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.address
              }
            case 'beneficiario_endereco_cep':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.cep.replace(/\D/g, '')
              }
            case 'beneficiario_dt_nascimento':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: defaultValues.birthdate.split('-').reverse().join('/')
              }
            case 'beneficiario_tp_sexo':
              return {
                tableId: field.tableId,
                columnId: field.columnId,
                value: values.gender
              }
            default:
              break;
          }
        }).filter(f => f);

        const quiz = configuration.quiz.filter(f => f.name.toLowerCase().includes('criscy')).map(q => {
          const obj = {
            id: q.id,
            configurationId: q.configurationId,
            createdAt: new Date().toLocaleDateString('fr-CA', { day: '2-digit', month: '2-digit', year: 'numeric' }),
            skuId: q.drugs.map(d => d.skuId)
          };

          // eslint-disable-next-line array-callback-return
          const questions = q.questions.map(question => {
            switch (question.questionAlias.toLowerCase()) {
              case "question_prescriber_crm":
                return {
                  id: question.id,
                  value: values.crm.replace(/\D/g, '')
                }
              case "question_prescriber_state":
                return {
                  id: question.id,
                  value: values.crmState
                }
              case "question_prescriber_name":
                return {
                  id: question.id,
                  value: this.state.prescriber.name
                }
              case "question_applied_dose":
                return {
                  id: question.id,
                  value: this.state.prescriber.appliedDose
                }
              case "question_responsible_acceptance":
                if (isMinor) {
                  return {
                    id: question.id,
                    value: defaultValues.consent
                  }
                }
                break;
              case "question_responsible_name":
                if (isMinor) {
                  return {
                    id: question.id,
                    value: values.responsibleName
                  }
                }
                break;
              case "question_responsible_document":
                if (isMinor) {
                  return {
                    id: question.id,
                    value: values.responsibleCpf.replace(/\D/g, '')
                  }
                }
                break;
              case "question_responsible_birth_date":
                if (isMinor) {
                  return {
                    id: question.id,
                    value: values.responsibleBirthdate.split('-').reverse().join('/')
                  }
                }
                break;
              case "question_responsible_degree_of_kinship":
                if (isMinor) {
                  return {
                    id: question.id,
                    value: values.degreeOfKinship
                  }
                }
                break;
              case "question_medication_use":
                return {
                  id: question.id,
                  value: isMinor ? '2' : '1'
                }
              case "question_accepted_term":
                return {
                  id: question.id,
                  value: defaultValues.consent
                }
              case "question_accepted_contact":
                return {
                  id: question.id,
                  value: values.contact
                }
              default:
                break;
            }
          }).filter(f => f);

          return { ...obj, questions };
        });

        const { data: result } = await api.post(`/Beneficiary/api/v1/Beneficiary/Dynamic/${window.cristaliaService.benefitId}?originRegister=1`,
          { fields, quiz }
        );

        if (result.success) {
          Swal.fire({
            icon: 'success',
            title: 'Parabéns!',
            text: 'Cadastro realizado com sucesso!',
            confirmButtonColor: 'var(--bs-success)'
          });
          resetForm({ values: undefined });
          return this.continue(new Event(undefined));
        } else {
          // result.messages.map((message) => message);
          Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: result.error.message,
            confirmButtonColor: 'var(--bs-danger)'
          });
        }
      }
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  async searchCep(cep) {
    this.setState(preState => ({
      loading: {
        ...preState.loading,
        cep: true
      }
    }));

    const { data } = await api.get(`Common/api/v1/Address/${cep.replace(/\D/g, '')}`);

    if (data.success) {
      const { data: address } = data;
      this.setState({address});
      this.setState(preState => ({
        loading: {
          ...preState.loading,
          cep: false
        },
        disabled: {
          ...preState.disabled,
          address: address.addressLine === "" ? false : true,
          neighborhood: address.neighborhood === "" ? false : true,
        }
      }));
      return data;
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops',
        text: 'CEP não existente!',
        confirmButtonColor: 'var(--bs-purple)'
      });
      this.setState(preState => ({
        loading: {
          ...preState.loading,
          cep: false
        }
      }));
    }
  }

  searchCepHandler = (e) => {
    const cep = e.target.value;
    if (!validateCEP(cep)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'CEP inválido!',
        confirmButtonColor: 'var(--bs-danger)'
      });
      return;
    }
    return this.searchCep(cep);
  }

  async searchPrescriber(crm, stateCode) {
    try {
      if (crm && stateCode) {
        const gridParams = JSON.stringify({
          Page: 0,
          ItemsPerPage: 0,
          Filter: [
            {
              property: "clientSystemNumber",
              operator: "=",
              value: crm
            },
            {
              property: "clientSystemSg",
              operator: "=",
              value: stateCode
            },
            {
              property: "prescriberTypeId",
              operator: "=",
              value: "1"
            }
          ],
          Sorter:[]
        });

        const { data } = await api.get(`/common/api/v1/Prescriber/Read?gridParams=${gridParams}`);

        if (data.success) {
          this.setState(preState => ({
            prescriber: {
              ...preState.prescriber,
              name: data.data[0].name
            }
          }))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: data.error.message,
            confirmButtonColor: 'var(--bs-danger)'
          });
        }
        return;
      }
    } catch(e) {
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Erro ao consultar CRM do médico!',
        confirmButtonColor: 'var(--bs-danger)'
      });
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(
      prevState.prescriber.crm !== this.state.prescriber.crm ||
      prevState.prescriber.stateCode !== this.state.prescriber.stateCode
    )
      setTimeout(() => {
        this.searchPrescriber(this.state.prescriber.crm, this.state.prescriber.stateCode);
      }, 500);
  }

  render() {
    const { values, handleChange } = this.props;

    return (
      <React.Fragment>
        <Row className="contact-complete-form">
          <h1 className="title">
            Finalize seu cadastro completando o formulário abaixo
          </h1>
          <div className="section-title-border mt-4 bg-warning"></div>
          <p className="section-describe pt-4">
            Olá, <b>{values.name}</b>.<br />
            Desejamos boas-vindas, agora falta pouco para finalizar o seu cadastro.<br />
            Complete os dados abaixo para fazer parte do programa.
          </p>
        </Row>
        <Formik
          {...this.formikProps}
          initialValues={{
            isMinor: values.isMinor,
            medicine: values.medicine,
            appliedDose: values.appliedDose,
            gender: values.gender,
            crm: values.crm,
            crmState: values.crmState,
            crmName: values.crmName,
            cep: values.cep,
            address: values.address,
            number: values.number,
            complement: values.complement,
            neighborhood: values.neighborhood,
            city: values.city,
            state: values.state,
            email: values.email,
            cellphone: values.cellphone,
            responsibleName: values.responsibleName,
            responsibleCpf: values.responsibleCpf,
            responsibleBirthdate: values.responsibleBirthdate,
            degreeOfKinship: values.degreeOfKinship,
            contact: values.contact
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <Form className="contact-form" onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="medicine"
                      name="medicine"
                      type="select"
                      placeholder="Medicamento"
                      disabled={true}
                      autoComplete="off"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('medicine', e.target.value);
                      }}
                      invalid={errors.medicine && touched.medicine}
                    >
                      <option value='CRISCY® REFIL CANETA'>CRISCY® REFIL CANETA</option>
                    </Input>
                    <Label for="medicine">Medicamento</Label>
                    <ErrorMessage name="medicine" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                <FormGroup floating>
                  <Input
                      id="appliedDose"
                      name="appliedDose"
                      type="appliedDose"
                      autoComplete="off"
                      placeholder="Dose Prescrita"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('appliedDose', e.target.value);
                        this.setState(preState => ({
                          prescriber: {
                            ...preState.prescriber,
                            appliedDose: e.target.value
                          }
                        }))
                      }}
                      invalid={errors.appliedDose && touched.appliedDose}
                    />
                    <Label for="appliedDose">Dose Prescrita</Label>
                    <ErrorMessage name="appliedDose" component="div" className="invalid-feedback" />
                </FormGroup>
                </Col>
                <Col lg={8}>
                  <FormGroup floating>
                    <Input
                      id="gender"
                      name="gender"
                      type="select"
                      autoComplete="off"
                      placeholder="Sexo do paciente"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('gender', e.target.value);
                      }}
                      invalid={errors.gender && touched.gender}
                    >
                      <option value=''></option>
                      <option value='M'>Masculino</option>
                      <option value='F'>Feminino</option>
                    </Input>
                    <Label for="gender">Sexo do paciente</Label>
                    <ErrorMessage name="gender" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <FormGroup floating>
                    <Input
                      id="crm"
                      name="crm"
                      type="text"
                      autoComplete="off"
                      placeholder="CRM do médico"
                      onChange={async e => {
                        handleChange(e);
                        setFieldValue('crm', e.target.value);
                        this.setState(preState => ({
                          prescriber: {
                            ...preState.prescriber,
                            crm: e.target.value
                          }
                        }))
                      }}
                      invalid={errors.crm && touched.crm}
                    />
                    <Label for="crm">CRM do médico</Label>
                    <ErrorMessage name="crm" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup floating>
                    <Input
                      id="crmState"
                      name="crmState"
                      type="select"
                      autoComplete="off"
                      placeholder="UF"
                      onChange={async e => {
                        handleChange(e);
                        setFieldValue('crmState', e.target.value);
                        this.setState(preState => ({
                          prescriber: {
                            ...preState.prescriber,
                            stateCode: e.target.value
                          }
                          }))
                      }}
                      invalid={errors.crmState && touched.crmState}
                    >
                      <>
                        <option value=''>{''}</option>
                        {states.map(state => (
                          <option key={state.value} value={state.value}>{state.label}</option>
                        ))}
                      </>
                    </Input>
                    <Label for="crmState">UF</Label>
                    <ErrorMessage name="crmState" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup floating>
                    <Input
                      id="crmName"
                      name="crmName"
                      type="text"
                      autoComplete="off"
                      placeholder="Nome do médico"
                      value={this.state.prescriber.name}
                      onChange={async e => {
                        handleChange(e);
                        setFieldValue('crmName', e.target.value);
                      }}
                      readOnly
                    />
                    <Label for="crmName">Nome do médico</Label>
                    <ErrorMessage name="crmName" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <InputMask
                      id="cep"
                      name="cep"
                      type="text"
                      mask="99999-999"
                      autoComplete="off"
                      placeholder="CEP"
                      className={
                        "form-control" +
                        (errors.cep && touched.cep
                          ? " is-invalid"
                          : ""
                        )
                      }
                      onChange={async e => {
                        if (validateCEP(e.target.value)) {
                          handleChange(e);
                          setFieldValue('cep', e.target.value);
                          const address = await this.searchCepHandler(e);
                          if (address !== undefined) {
                            if (address.success) {
                              this.props.bindCep(address.data);
                              setFieldValue('address', address.data.addressLine);
                              setFieldValue('city', address.data.city.name);
                              setFieldValue('neighborhood', address.data.neighborhood);
                              setFieldValue('state', address.data.city.state.stateCode);
                            }
                          }
                        }
                      }}
                    >
                    </InputMask>
                    <Label for="cep">CEP</Label>
                    <ErrorMessage name="cep" component="div" className="invalid-feedback" />
                    {
                      this.state.loading.cep
                        ? (
                          <span
                            className="spinner-border text-purple spinner-border-sm"
                            role="status"
                            aria-hidden={this.state.loading.cep}
                          ></span>
                        )
                        : null
                    }
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={9}>
                  <FormGroup floating>
                    <Input
                      id="address"
                      name="address"
                      type="text"
                      autoComplete="off"
                      placeholder="Endereço"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('address', e.target.value);
                      }}
                      value={values.address}
                      readOnly={this.state.disabled.address}
                      invalid={errors.address && touched.address}
                    />
                    <Label for="address">Endereço</Label>
                    <ErrorMessage name="address" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col lg={3}>
                  <FormGroup floating>
                    <Input
                      id="number"
                      name="number"
                      type="text"
                      autoComplete="off"
                      placeholder="Nº"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('number', e.target.value);
                      }}
                      invalid={errors.number && touched.number}
                    />
                    <Label for="number">Nº</Label>
                    <ErrorMessage name="number" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="complement"
                      name="complement"
                      type="text"
                      autoComplete="off"
                      placeholder="Complemento"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('complement', e.target.value);
                      }}
                      invalid={errors.complement && touched.complement}
                    />
                    <Label for="complement">Complemento</Label>
                    <ErrorMessage name="complement" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FormGroup floating>
                    <Input
                      id="neighborhood"
                      name="neighborhood"
                      type="text"
                      autoComplete="off"
                      placeholder="Bairro"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('neighborhood', e.target.value);
                      }}
                      readOnly={this.state.disabled.neighborhood}
                      value={values.neighborhood}
                      invalid={errors.neighborhood && touched.neighborhood}
                    />
                    <Label for="neighborhood">Bairro</Label>
                    <ErrorMessage name="neighborhood" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup floating>
                    <Input
                      id="city"
                      name="city"
                      type="text"
                      autoComplete="off"
                      placeholder="Cidade"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('city', e.target.value);
                      }}
                      value={values.city}
                      readOnly={true}
                      invalid={errors.city && touched.city}
                    />
                    <Label for="city">Cidade</Label>
                    <ErrorMessage name="city" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup floating>
                    <Input
                      id="state"
                      name="state"
                      type="select"
                      autoComplete="off"
                      placeholder="UF"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('state', e.target.value);
                      }}
                      value={values.state}
                      readOnly={true}
                      invalid={errors.state && touched.state}
                    >
                      <>
                        <option value=''>{''}</option>
                        {states.map(state => (
                          <option key={state.value} value={state.value}>{state.label}</option>
                        ))}
                      </>
                    </Input>
                    <Label for="state">UF</Label>
                    <ErrorMessage name="state" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      placeholder="E-mail"
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('email', e.target.value);
                      }}
                      invalid={errors.email && touched.email}
                    />
                    <Label for="email">E-mail</Label>
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <InputMask
                      id="cellphone"
                      name="cellphone"
                      type="text"
                      mask="(99) \99999-9999"
                      autoComplete="off"
                      placeholder="Celular"
                      className={
                        "form-control" +
                        (errors.cellphone && touched.cellphone
                          ? " is-invalid"
                          : ""
                        )
                      }
                      onChange={e => {
                        handleChange(e);
                        setFieldValue('cellphone', e.target.value)
                      }}
                    >
                    </InputMask>
                    <Label for="cellphone">Celular</Label>
                    <ErrorMessage name="cellphone" component="div" className="invalid-feedback" />
                  </FormGroup>
                </Col>
              </Row>

              {
                /**
                 * Check isMinor
                 */
                values.isMinor
                  ? (
                    <div>
                      <Row>
                        <div className="section-title-border mt-4 bg-warning"></div>
                        <p className="section-describe pt-4">
                          Conforme o Regulamento do programa, <b>pacientes</b> menores de 18 (dezoito) anos&nbsp;
                          poderão ser considerados <b>Participantes</b> desde que cadastrados por meio&nbsp;
                          de seu responsável legal, sempre respeitando as demais condições do <Regulations />.
                        </p>
                      </Row>
                      <Row>
                        <FormGroup floating>
                          <Input
                            id="responsibleName"
                            name="responsibleName"
                            type="text"
                            autoComplete="off"
                            placeholder="Nome do responsável"
                            onChange={e => {
                              handleChange(e);
                              setFieldValue('responsibleName', e.target.value);
                            }}
                            invalid={errors.responsibleName && touched.responsibleName}
                          />
                          <Label for="responsibleName">Nome do responsável</Label>
                          <ErrorMessage name="responsibleName" component="div" className="invalid-feedback" />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup floating>
                          <InputMask
                            id="responsibleCpf"
                            name="responsibleCpf"
                            type="text"
                            mask="999.999.999-99"
                            autoComplete="off"
                            placeholder="CPF do responsável"
                            className={
                              "form-control" +
                              (errors.responsibleCpf && touched.responsibleCpf
                                ? " is-invalid"
                                : ""
                              )
                            }
                            onChange={e => {
                              handleChange(e);
                              setFieldValue('responsibleCpf', e.target.value)
                            }}
                          >
                          </InputMask>
                          <Label for="responsibleCpf">CPF do responsável</Label>
                          <ErrorMessage name="responsibleCpf" component="div" className="invalid-feedback" />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup floating>
                          <Input
                            id="responsibleBirthdate"
                            name="responsibleBirthdate"
                            type="date"
                            autoComplete="off"
                            placeholder="Data de nascimento"
                            onChange={e => {
                              handleChange(e);
                              setFieldValue('responsibleBirthdate', e.target.value);
                            }}
                            invalid={errors.responsibleBirthdate && touched.responsibleBirthdate}
                          />
                          <Label for="responsibleBirthdate">Data de nascimento</Label>
                          <ErrorMessage name="responsibleBirthdate" component="div" className="invalid-feedback" />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup floating>
                          <Input
                            id="degreeOfKinship"
                            name="degreeOfKinship"
                            type="select"
                            autoComplete="off"
                            placeholder="Grau de parentesco"
                            onChange={e => {
                              handleChange(e);
                              setFieldValue('degreeOfKinship', e.target.value);
                            }}
                            invalid={errors.degreeOfKinship && touched.degreeOfKinship}
                          >
                            <option value=''>{''}</option>
                            {degreeOfKinship.map(degree => (
                              <option key={degree.value} value={degree.value}>{degree.label}</option>
                            ))}
                          </Input>
                          <Label for="degreeOfKinship">Grau de parentesco</Label>
                          <ErrorMessage name="degreeOfKinship" component="div" className="invalid-feedback" />
                        </FormGroup>
                      </Row>
                    </div>
                  )
                  : undefined
              }
              <Row>
                <Col lg={12} className="text-start">
                  <FormGroup check>
                    <Field
                      id="contact"
                      name="contact"
                      type="checkbox"
                      checked={values.contact}
                      onChange={e=>{
                        handleChange({name: 'contact', value: !values.contact});
                        setFieldValue('contact', !values.contact);
                      }}
                      className={"form-check-input"}
                    />
                    <Label check for="contact">Aceito receber o contato do Programa Cristália Saúde</Label>
                  </FormGroup>
                  <button type="submit" id="submit" disabled={isSubmitting} className="submitBnt btn btn-purple btn-rounded mt-4">
                    {
                      isSubmitting
                        ? (<span className="spinner-border text-light spinner-border-sm" style={{marginRight: '.5rem'}} role="status" aria-hidden={isSubmitting}></span>)
                        : null
                    }
                    Cadastrar
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
