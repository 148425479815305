import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Regulations from "./Regulations";
import { Link } from "react-router-dom";

export default class Consent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <React.Fragment>
        <span 
          className={
            (this.props.spanTextColor ? this.props.spanTextColor : "text-primary") +
            " cursor__pointer " + this.props.spanClasses
          } 
          onClick={this.toggle}
        >
          Termo de Consentimento para dados sensíveis
        </span>
        <Modal 
          isOpen={this.state.modal} 
          toggle={this.toggle} 
          {...this.props.modalProps}
          scrollable={true}
          fullscreen={"md"}
          size={"lg"}
          zIndex={9999}
          className="d-flex"
          {...this.props.modalProps}
        >
          <ModalHeader 
            className="text-primary justify-content-center" 
            toggle={this.toggle}
          >
            Termo de Consentimento
          </ModalHeader>
          <ModalBody style={{ padding: '10px 50px' }}>
            <p>
              Para que o Cristália Produtos Químicos Farmacêuticos Ltda. <b>(“Cristália”)</b> possa cumprir com a&nbsp;
              Lei Geral de Proteção de Dados - Lei no 13.709/2018 <b>(“LGPD”)</b>, precisamos solicitar o seu consentimento&nbsp;
              para o tratamento de dados pessoais na forma de <Regulations /> do Programa Cristália Saúde <b>(“Política”)</b> . 
            </p>
            <p>
              Antes de dar o seu consentimento, pedimos que leia atentamente a Política, que descreve, entre outros, os direitos dos titulares de dados. 
            </p>
            <p>
              Para aderir ao Programa Cristália Saúde, você deverá fazer o seu cadastro e precisará fornecer alguns dados pessoais&nbsp;
              à EMPRESA OPERADORA DO PROGRAMA. Os dados pessoais são tratados pela EMPRESA OPERADORA DO PROGRAMA EPHARMA – PBM DO BRASIL S/A,&nbsp;
              sociedade anônima com sede na cidade de Barueri, Estado de São Paulo, na Al. Mamoré, nº 989, 9º andar, Alphaville, CEP 06454-040,&nbsp;
              inscrita no CNPJ sob nº 03.448.808/0001-24 <b>(“EPHARMA”)</b>, para viabilizar o acesso dos pacientes aos benefícios&nbsp;
              personalizados do Programa Cristália Saúde. Portanto, cabe esclarecer que todos os dados pessoais enviados para cadastro no&nbsp;
              Programa Cristália Saúde são processados pela EPHARMA, qual está resguardada por obrigações de sigilo e confidencialidade e&nbsp;
              detém obrigação contratual de pseudonimizar todo e qualquer dado pessoal antes de passar qualquer informação gerencial do&nbsp;
              Programa Cristália Saúde ao Cristália. A EPHARMA, poderá, todavia, compartilhar com o Cristália dados pessoais de pacientes&nbsp;
              que tenham feito Requerimentos relacionados a seus dados pessoais (esclarecimentos, deleção, portabilidade, etc) e/ou Relatos&nbsp;
              de farmacovigilância a fim de cumprir com as obrigações legais relativas a dados legais e/ou regulatórias.
            </p>
            <p>
              Portanto, caso não concorde em fornecer seus dados pessoais, não será possível aderir e participar do Programa. 
              Ressalte-se que a recusa em fornecer seus dados pessoais não irá afetar de nenhuma maneira o acesso aos produtos do&nbsp;
              Cristália fora do Programa Cristália Saúde. 
            </p>
            <p>
              Por favor, LEIA ATENTAMENTE AS CONDIÇÕES AQUI DESCRITOS ANTES DE FORNECER SEUS DADOS PESSOAIS. Caso tenha alguma dúvida,&nbsp;
              não faça o seu cadastro e/ou entre em contato com o Cristália por meio do email:&nbsp;
              <Link to="mailto:dpo@cristalia.com.br" classes="text-primary">dpo@cristalia.com.br</Link>.
            </p>
            <p>
              Os dados pessoais coletados serão tratados para as seguintes finalidades:
              <ul>
                <li>
                  CPF para realizar o seu cadastro no Programa Cristália Saúde;
                </li>
                <li>
                  Data de Nascimento para garantir que pacientes menores de idade estejam cadastrados através dos seus responsáveis legais bem como entender o perfil de participantes que aderem ao Programa Cristália Saúde;
                </li>
                <li>
                  E-mail e celular para que possamos nos comunicar com você sobre atualizações, informações relevantes sobre o programa e possamos enviar materiais publicitários;
                </li>
                <li>
                  Endereço completo (CEP, cidade, bairro, UF, endereço, número e complemento) para envio do kit inicial, brindes, materiais exclusivos do programa e para entender como é a distribuição e venda do Medicamento.
                </li>
                <li>
                  Nome e gênero para envio de comunicações personalizada e entendimento do perfil de participantes que aderem ao Programa Cristália Saúde;
                </li>
                <li>
                  CRM e nome do médico prescritor da receita para validação do profissional de saúde devidamente habilitado e para entender como é a distribuição e venda do Medicamento.
                </li>
              </ul>
            </p>
            <p>
              Caso o Paciente faça qualquer reporte de farmacovigilância dentro de quaisquer das Plataformas do Programa Cristália Saúde,&nbsp;
              os dados pessoais coletados serão compartilhados em sua forma bruta com o Cristália, sendo certo que neste caso os dados&nbsp;
              pessoais do relator serão tratados em conformidade com as disposições constantes na Notificação de uso e tratamento de dados&nbsp;
              pessoais Reportes do Serviço de Atendimento ao Consumidor (SAC) ou relatos de farmacovigilância.
            </p>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
