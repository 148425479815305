import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import SectionTitle from "../common/SectionTitle";
import SearchAccreditedPharmacy from "./SearchAccreditedPharmacy";
import "./accreditedPharmacy.scss";

// import api from "../../services/api";
import stores from "./stores.json";

export default class AccreditedPharmacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        page: 1,
        itemsPerPage: 10
      },
      loading: {
        store: false
      },
      stores: []
    };

    this.getStores = this.getStores.bind(this);
  }

  componentDidMount() {
    this.getStores();
  }

  getStores = async () => {
    this.setState(preState => ({
      loading: {
        ...preState.loading,
        store: true
      }
    }));

    setTimeout(async () => {
      await this.setState(preState => ({
        ...preState,
        stores: stores,
        loading: {
          ...preState.loading,
          store: false
        }
      }));
    }, 1000);

    /*const params = JSON.stringify({
      page: this.state.pagination.page,
      itemsPerPage: this.state.pagination.itemsPerPage,
      filter: [
        {
          property: "benefitId",
          operator: "=",
          value: process.env.REACT_APP_BENEFITID
        }
      ],
      sorter: []
    });

    const {data} = await api.get(`client/api/v1/Benefit/Chain?gridParams=${params}`);

    if (data.success) {
      await this.setState(preState => ({
        ...preState,
        stores: data.data,
        loading: {
          ...preState.loading,
          store: false
        }
      }));
    }*/
  }

  render() {
    return (
      <section className="section bg-light pt-5" id="accredited-pharmacy">
        <Container fluid={true}>
          <SectionTitle
            title="Redes credenciadas"
            desc="Consulte abaixo os deliveries e suas abrangências"
            styleSubtitle="section-accredited-pharmacy-subtitle"
          />

          <Row className="d-flex justify-content-center">
            <Col lg={10}>
              <Row className="mt-4 justify-content-center pharmacy-list">
                <SearchAccreditedPharmacy
                  stores={this.state.stores}
                  loading={this.state.loading.store}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
