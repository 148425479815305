import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from "react-router-dom";

export default class Regulations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <React.Fragment>
        <span
          className={
            (this.props.spanTextColor ? this.props.spanTextColor : "text-primary") +
            " cursor__pointer " +
            this.props.spanClasses
          }
          onClick={this.toggle}
        >
          Regulamento e Política de Privacidade
        </span>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          scrollable={true}
          fullscreen={"md"}
          size={"lg"}
          zIndex={9999}
          className="d-flex"
          {...this.props.modalProps}
        >
          <ModalHeader
            className="text-primary justify-content-center"
            toggle={this.toggle}
          >
            Regulamento e Política de Privacidade – Programa Cristália Saúde
          </ModalHeader>
          <ModalBody style={{ padding: '10px 50px' }}>
            <p>
              Nós do Cristália Produtos Químicos Farmacêuticos Ltda., localizado no Estado de São Paulo, na cidade de Itapira,&nbsp;
              na Rodovia Itapira-Lindóia, s/nº, KM 14 – Fazenda Estância Cristália, CEP 13.974-900, inscrito no CNPJ sob o nº 44.734.671/0001-51&nbsp;
              (<b>“Cristália”</b> ou <b>“nós”</b>), com o objetivo de fornecer aos participantes do Programa Saúde Cristália (<b>“Participante”</b> ou <b>“você”</b>)&nbsp;
              uma visão transparente das nossas práticas relacionadas à coleta, armazenamento e formas de uso de dados pessoais (<b>“Dados Pessoais”</b>)&nbsp;
              referente ao Programa Cristália Saúde (“Programa Cristália Saúde”), apresentamos este  Regulamento do Programa Cristália Saúde (<b>“Regulamento”</b>) e no&nbsp;
              mesmo documento a Política de Privacidade (<b>“Política”</b>).
            </p>
            <p>
              Caso, após a leitura deste Regulamento e da Política, você tenha qualquer dúvida sobre o Programa Cristália Saúde, basta entrar em contato com nossa&nbsp;
              central de atendimento ao cliente e, caso tenha dúvidas quanto à forma de tratamento de seus Dados Pessoais pelo Cristália, recomendamos&nbsp;
              que entre em contato conosco por meio do e-mail:&nbsp;
              <Link to="mailto:dpo@cristalia.com.br" classes="text-primary">dpo@cristalia.com.br</Link>.
            </p>
            <p>
              <b>I.  SOBRE O PROGRAMA CRISTÁLIA SAÚDE</b><br />
              O Programa Cristália Saúde oferece exclusivamente suporte e apoio apenas aos familiares e pacientes durante todo o tratamento com o medicamento&nbsp;
              Criscy® administrado através de uma caneta aplicadora de alta tecnologia (Crispen). Nossos serviços foram pensados para assegurar o uso correto&nbsp;
              e seguro do medicamento para que todos se sintam confortáveis durante a utilização e recebam toda orientação necessária.&nbsp;
              O Programa Cristália Saúde oferece :
              <ul>
                <li>
                  Benefícios comerciais para aquisição dos produtos prescritos pelos médicos dos pacientes a fim de garantir acesso à medicação;
                </li>
                <li>
                  Materiais complementares e necessários durante todas as aplicações quando este for um tratamento pediátrico;
                </li>
                <li>
                  Informações educacionais sobre distúrbios do crescimento, conforme interesse do paciente;
                </li>
                <li>
                  Informativos com orientações de armazenamento, transporte, aplicação e manuseio do medicamento;
                </li>
                <li>
                  Central de Atendimento formado por um time de enfermagem especializado e habilitado para informar sobre o manuseio do medicamento&nbsp;
                  conforme prescrição médica, além das melhores práticas de transporte e conservação. Todo o contato pode ser realizado por videoconferência&nbsp;
                  ou telefone de segunda a sexta-feira, das 8h00 às 20h00.
                </li>
              </ul>
            </p>
            <p>
              Determinados benefícios do Programa Cristália Saúde estão em fase de testes e poderão ser oferecidos somente para pacientes&nbsp;
              residentes em algumas regiões do Brasil, ou por tempo limitado. Para saber mais sobre os benefícios,&nbsp;
              <Link target={"_blank"} to={"/program"}>clique aqui.</Link>
            </p>
            <p>
              <b>1.	QUEM PODE PARTICIPAR DO PROGRAMA CRISTÁLIA SAÚDE?</b><br/>
              Apenas pacientes com prescrição válida emitida por médico devidamente habilitado para tratamento de Criscy® administrado através de uma&nbsp;
              caneta aplicadora de alta tecnologia (Crispen). Ao aderir ao Programa Cristália Saúde, o paciente confirma que: (i) o medicamento foi&nbsp;
              devidamente prescrito pelo médico com número de inscrição no CRM informado pelo paciente no momento do cadastro no Programa Cristália Saúde;&nbsp;
              e (ii) de acordo com a indicação do medicamento prevista em bula. A fim de evitar o uso indiscriminado de medicamentos, o Programa Cristália Saúde&nbsp;
              poderá limitar uma quantidade máxima de medicamentos que podem ser adquiridos com os descontos aplicáveis, reservando-se ao direito de investigar&nbsp;
              e apurar eventuais tentativas de fraude ao Programa.
            </p>
            <p>
              <b>2.	DURAÇÃO</b><br/>
              O Programa Cristália Saúde tem prazo indeterminado de duração. O Cristália se reserva o direito de alterar, suspender ou encerrar o&nbsp;
              Programa Cristália Saúde a qualquer momento, mediante prévio comunicado aos participantes por meio do website ou e-mail.&nbsp;
              O Participante pode solicitar sua exclusão do Programa a qualquer momento, por meio do <b>0800 000 6565</b>
            </p>
            <p>
              <b>3.	OUTRAS INFORMAÇÕES</b><br/>
              A participação no Programa Cristália Saúde é pessoal e intransferível, ou seja, ficará vinculada ao número de CPF disponibilizado pelo Participante durante o seu cadastro no programa. Ao realizar a inscrição, o paciente compreende que terá direito apenas à uma caneta aplicadora de alta tecnologia (Crispen) para o tratamento de Criscy®.
              <br/>Cada Participante até 18 anos incompletos (até 17 anos e 29 dias) terá direito a uma única caneta durante todo o Programa Cristália Saúde, exceto em caso de defeito de fabricação. Nesses casos, o Participante deverá entrar em contato com o Cristália por meio do [0800 000 6565- ] , para requerer a troca do produto. Apenas canetas comprovadamente com defeitos serão substituídas pelo Cristália, sem qualquer custo ao Participante.
              <br/>A substituição da caneta por quebra, será mediante avaliação técnica dos profissionais de saúde do PSP e a caneta será recolhida para testes.
              <br/>O Cristália não ficará responsável pela substituição da caneta em caso de mau uso, perda ou qualquer outra situação não listada anteriormente.
              <br/>Caso você deseje canetas adicionais, por favor, procure um de nossos pontos de vendas disponíveis.
            </p>
            <p>
              <b>POLÍTICA DE PRIVACIDADE</b>
              <br/><br/><b>1. O que é o Programa Cristália Saúde?</b>
              <br/><b>1.1.</b> O Programa Cristália Saúde é um programa de relacionamento exclusivo do Cristália para usuários do medicamento Criscy® administrado através de uma caneta aplicadora de alta tecnologia Crispen.
              (“Medicamento”), que tem como objetivo oferecer diversos serviços e benefícios aos Participantes. Para saber mais sobre os benefícios,&nbsp;
              <Link target={"_blank"} to={"/program"}>clique aqui</Link>. O Programa Cristália Saúde será realizado em parceria com a EPHARMA - PBM DO BRASIL S/A, sociedade anônima com sede na cidade de Barueri, Estado de São Paulo, na Al. Mamoré, nº 989, 9º andar, Alphaville, CEP 06454-040, inscrita no CNPJ sob nº 03.448.808/0001-24 (“EPHARMA”), que atuará como operadora dos dados, conforme previsto na Lei 13.709/18 - Lei Geral de Proteção de Dados (“LGPD”). Nos termos da lei, operador é aquele que coletará os dados seguindo as instruções do controlador.
              <br/><b>1.2.</b> A EPHARMA é uma plataforma de gestão de benefícios de medicamentos e será responsável pela coleta de dados dos participantes, conforme definido pelo Cristália.
              <br/><br/><b>2. Quais Dados Pessoais o Cristália coletará?</b>
              <br/><b>2.1.</b> Como parte do Programa Cristália Saúde, o Cristália solicitará que você preencha um cadastro de participação. Nesse cadastro, você terá que fornecer alguns Dados Pessoais que serão tratados exclusivamente no contexto do programa. Esses dados são:  nome completo, data de nascimento, CPF, e-mail, gênero, endereço completo (CEP, cidade, bairro, UF, endereço, número e complemento), faixa etária, celular e informações constantes de receita médica, tais como medicamento prescrito, indicação de uso (pediátrico ou adulto), dosagem, dias da semana em que o medicamento será utilizado, CRM e nome completo do médico que prescreveu a receita.
              <br/><b>2.2.</b> Ressaltamos que não solicitaremos cópia de nenhum documento seu, inclusive receitas médicas.
              <br/><b>2.3.</b> O Cristália somente utilizará os Dados Pessoais para as finalidades e de acordo com as bases legais indicadas abaixo.
              <br/><br/><b>3. Como o Cristália utilizará Dados Pessoais?</b>
              <br/><b>3.1.</b> A participação no Programa Cristália Saúde é voluntária, mas se você se inscrever, utilizaremos os Dados Pessoais descritos acima para que possamos oferecer benefícios exclusivos, tais como descontos no Medicamento, que serão computados quando você realizar uma compra em farmácias da rede credenciada ou ainda, para enviar o kit de boas-vindas ao Programa Cristália Saúde, que inclui uma caneta aplicadora de alta tecnologia Crispen.
              <br/><b>3.2.</b> Podemos ainda utilizar seus Dados Pessoais para realizar estudos estatísticos para compreender o perfil dos Participantes e entender como é a distribuição e venda do Medicamento, bem como para realizar o planejamento de produção e atendimento adequado da demanda do Medicamento. Sempre que possível, adotaremos técnicas de pseudoanonimização para proteger os seus Dados Pessoais.
              <br/><b>3.3.</b> O Cristália também poderá utilizar os Dados Pessoais para entrar em contato com você para realizar ações de marketing farmacêutico, como por exemplo, o envio de informações relevantes sobre nossos produtos.
              <br/><b>3.4.</b> Poderemos entrar em contato com você para todas as finalidades acima e para explicar a utilização do Medicamento, bem como explicar os benefícios do Programa Cristália Saúde. Os contatos poderão ocorrer por meios de comunicação diversos como ligação telefônica, WhatsApp, SMS ou e-mail.
              <br/><b>3.5.</b> Caso você seja um médico prescritor do Medicamento, poderemos também entrar em contato com você pode meio da nossa Força de Vendas, bem como enviar informações sobre novos produtos, convidá-lo para webinars, congressos, conferências e eventos em geral, dentre outros.
              <br/><b>3.6.</b> Nas hipóteses mencionadas acima, o Cristália será controlador dos seus Dados Pessoais e poderá tratá-los com base em seu consentimento, para tutela da saúde ou para atender seu legítimo interesse, quando relacionado aos dados dos médicos prescritores do Medicamento.
              <br/><br/><b>4. Como o Cristália armazena os Dados Pessoais coletados?</b>
              <br/><b>4.1.</b> O Cristália fará todo o possível para manter os Dados Pessoais sempre seguros, inclusive, adotará medidas de segurança e de proteção compatíveis com a natureza dos dados coletados, armazenados e utilizados e as práticas apropriadas de mercado. Nós adotamos diversas medidas de segurança, tais como: (a) emprego de sistemas de autenticação para acesso aos dados, (b) realização de mapeamentos e testes regulares de segurança, (c) sistemas de backup de dados, (d) uso de criptografia; e (e) utilização de firewalls e outras ferramentas.
              <br/><b>4.2.</b> Apesar de empregarmos nossos melhores esforços, não podemos garantir que tais medidas de segurança sejam isentas de erros ou que não estejam sujeitas à interferência de terceiros (hackers, entre outros). Por sua natureza, qualquer medida de segurança pode falhar e qualquer dado pode se tornar público. O CRISTÁLIA NÃO SERÁ RESPONSÁVEL POR ESSE TIPO DE VAZAMENTO DE DADOS.
              <br/><b>4.3.</b> Todas as informações coletadas serão armazenadas com alto padrão de segurança em servidores próprios ou em servidores operados e controlados exclusivamente pelo Cristália, localizados no Brasil e no exterior.
              <br/><b>4.4.</b> O CRISTÁLIA PODERÁ CONTRATAR SUB OPERADORES, NACIONAIS OU ESTRANGEIROS, PARA TRATAMENTO DOS DADOS PESSOAIS, INCLUINDO, SEM LIMITAÇÃO, SUB OPERADORES RESPONSÁVEIS POR REALIZAR A MANUTENÇÃO E ATUALIZAÇÃO DO NOSSO WEBSITE. DESSA FORMA, VOCÊ AUTORIZA DESDE JÁ, SE ASSIM NECESSÁRIO, A TRANSFERÊNCIA E ARMAZENAMENTO DE DADOS PESSOAIS PARA OPERADORES DO TRATAMENTO LOCALIZADOS FORA DO BRASIL. Tais sub operadores, nacionais ou estrangeiros, estarão contratualmente submetidos às mesmas exigências de segurança e confidencialidade aplicáveis ao Cristália.
              <br/><br/><b>5. Quais são os direitos dos titulares?</b>
              <br/><b>5.1.</b> O Cristália disponibiliza mecanismos para que titulares exerçam os seus direitos legais sobre os Dados Pessoais, conforme previstos no artigo 18 da LGPD. Neste item, detalhamos esses direitos e como você pode exercê-los:
              <br/><b>A.</b>&nbsp; Confirmação da existência de tratamento: você pode confirmar se nós estamos tratando os seus Dados Pessoais.
              <br/><b>B.</b>&nbsp; Acesso aos Dados Pessoais: você pode acessar os seus Dados Pessoais, inclusive solicitando cópia dos dados tratados.
              <br/><b>C.</b>&nbsp; Correção de dados incompletos, inexatos ou desatualizados: você pode solicitar alteração ou correção dos seus Dados Pessoais que estejam incorretos.
              <br/><b>D.</b>&nbsp; Portabilidade: você pode solicitar a portabilidade dos seus dados para outro fornecedor de serviços, quando aplicável.
              <br/><b>E.</b>&nbsp; Eliminação dos Dados Pessoais: você pode solicitar a exclusão de seus Dados Pessoais que tratamos quando estes tiverem sido coletados e tratados com base em seu consentimento.
              <br/><b>F.</b>&nbsp; Informação sobre compartilhamento: você pode solicitar que nós informemos se compartilhamos seus dados com outros e, se sim, como quem.
              <br/><b>G.</b>&nbsp; Revogação do consentimento: você pode, a qualquer momento e a seu critério, revogar o consentimento anteriormente fornecido para tratamento de Dados Pessoais, permanecendo os tratamentos realizados com base nele, até tal momento, válidos.
              <br/><b>H.</b>&nbsp; Anonimização, bloqueio ou eliminação de dados: você pode solicitar que o Cristália proceda à anonimização, ao bloqueio ou anonimização de Dados Pessoais que sejam excessivos.
              <br/><b>I.</b>&nbsp; Oposição ao tratamento de dados: você pode se opor ao tratamento de Dados Pessoais que seja fundado em uma base legal que não o consentimento.
              <br/><b>J.</b>&nbsp; Revisão de decisões tomadas exclusivamente com base em tratamento automatizado de dados: caso o Cristália tome qualquer decisão baseada exclusivamente em tratamento de Dados Pessoais automatizados, você pode solicitar que essas decisões sejam revistas.
              <br/><b>K.</b>&nbsp; Informação sobre a possibilidade de não consentir: você pode solicitar, pelos canais de atendimento, informações sobre a possibilidade de não consentir com o tratamento de Dados Pessoais e suas potenciais consequências.
              <br/><b>5.2.</b> Consequências do cumprimento com solicitação de exclusão de dados. É importante ressaltarmos que o processamento de seus Dados Pessoais é uma condição para sua participação no Programa Cristália Saúde. Logo, se você solicitar a exclusão ou revogar o seu consentimento para o tratamento de seus Dados Pessoais, isso poderá acarretar também em perda dos benefícios e de sua inscrição no Programa Cristália Saúde. O seu pedido de exclusão de dados ou revogação de consentimento não implica que nós não poderemos utilizar dados de forma distinta, sem qualquer identificação pessoal, não individualizada e anonimizada. Finalmente, se não formos atender seu pedido, nós explicaremos os motivos da recusa de exclusão dos seus Dados Pessoais.
              <br/><b>5.3.</b> Retenção de dados. Manteremos seus Dados Pessoais apenas pelo tempo em que forem necessários, de acordo com as finalidades acima elencadas e as leis e regulamentações aplicáveis. Ainda, sem prejuízo do previsto na Cláusula 5.1. acima, poderemos guardar os Dados Pessoais por um período superior ao de guarda legal, em cumprimento de eventuais ordens de autoridade públicas, para nos defendermos em processos judiciais e/ou administrativos e em casos nos quais os Dados Pessoais tenham sido devidamente anonimizados.
              <br/><br/><b>6. Como você poderá contatar o Cristália em relação a essa política?</b>
              <br/><b>6.1.</b> Em caso de dúvida ou requerimento do exercício de algum dos direitos descritos no item 5, você poderá entrar em contato com o encarregado de dados do Cristália, por meio do e-mail:&nbsp;
              <Link to="mailto:dpo@cristalia.com.br" classes="text-primary">dpo@cristalia.com.br</Link>.
              <br/><br/><b>7. Em que situações o Cristália poderá transferir seus Dados Pessoais para Terceiros?</b>
              <br/><b>7.1.</b> Em adição às hipóteses já mencionadas nesta Política, os Dados Pessoais poderão também ser transferidos a terceiros, nos seguintes casos:
              <br/><b>A.</b>&nbsp; Prestação dos Serviços. O Cristália poderá contratar terceiros que o auxiliem na prestação de seus serviços, como por exemplo, servidores de armazenamento em nuvem e, a própria EPHARMA.
              <br/><b>B.</b>&nbsp; Novos Negócios. Poderá haver a transferência de Dados Pessoais em processos de aquisição, venda, fusão, reorganização societária ou qualquer outra mudança de controle do Cristália. Nesse caso, o Cristália irá garantir a que a pessoa, física ou jurídica, que venha a acessar ou assumir o controle sobre os dados tratados nos termos desta Política sejam também vinculados a ela, garantindo a continuidade da proteção dos Dados Pessoais.
              <br/><b>C.</b>&nbsp; Requisição judicial ou administrativa. O Cristália pode compartilhar Dados Pessoais em caso de requisição judicial ou administrativa ou em cumprimento de dever legal.
              <br/><b>D.</b>&nbsp; Conforme autorizado por lei. Em outras situações nas quais haja a necessidade de compartilhamento dos seus dados pessoais, seguiremos o disposto em lei.
              <br/><br/><b>8. Outros</b>
              <br/><b>8.1.</b> Os termos desta Política são regidos e deverão ser interpretados de acordo com as Leis da República Federativa do Brasil.
              <br/><b>8.2.</b> Com a finalidade de garantir a sua privacidade e a segurança dos seus dados, o Cristália se compromete a regularmente reavaliar as suas políticas de tratamento de dados, incluindo esta Política, conforme necessário. Manteremos você informado caso alteremos a forma de coleta e tratamento dos seus Dados Pessoais.
            </p>
            <p>
              Última atualização, 17 de outubro de 2024.
            </p>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
