import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./video.scss";

import SectionTitle from "../common/SectionTitle";

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: this.props.width || 555,
      height: this.props.height || 420,
      autoplay: this.props.autoplay || false,
      source: "https://www.youtube.com/embed/pvIMTsURjr4",
      showButton: !window.location.pathname.includes('program') && !window.location.pathname.includes('growingHormone')
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-video"
          id="video"
          style={{
            paddingBottom: '0px',
            backgroundColor: '#dde0f7',
            position: 'relative',
          }}
        >
          <Container>
            <SectionTitle
              title="Conheça o Programa Cristália Saúde"
              desc="Como este programa pode te beneficiar"
            />

            <Row className="d-flex justify-content-center">
              <Col lg={8}>
                <Row className="mt-4 mb-5 pb-5">
                  <iframe
                    title="Programa Cristália Saúde"
                    src={this.state.source}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; loop;"
                    width={this.state.width}
                    height={this.state.height}
                    disablePictureInPicture
                    allowFullScreen
                    playsInline
                    preload='metadata'
                    autoPlay={this.state.autoplay}
                  >
                  </iframe>
                </Row>
              </Col>
              {this.state.showButton && (
                <div className="mb-5 text-center col-lg-8">
                  <Link
                      to={'/program#contact'}
                      className="btn btn-purple btn-rounded btn-know-more"
                  >
                    Quero me cadastrar
                  </Link>
                </div>
              )}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
