import React, { Component } from "react";

import Section from "./section";
import NavbarPage from "../../components/Navbar/Navbar";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/footer";
import IndicationsForUse from "../../components/IndicationsForUse/IndicationsForUse";

import scrollTo from "../../util/scrollTo";

export default class GrowingHormoneIndication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: ""
    };
  }

  componentDidMount() {
    scrollTo();
  }

  render() {
    return (
      <React.Fragment>
        <NavbarPage
          navClass={this.state.navClass}
        />

        <Section />

        <IndicationsForUse />

        <Contact />

        <Footer />
      </React.Fragment>
    );
  }
}
