function handleScroll(hash) {
  let elem = document.getElementById(hash.slice(1));
  if (elem) {
    elem.scrollIntoView({ behavior: "smooth" });
  }
}

export default function scrollTo(hash) {
  if (window.location.hash) {
    handleScroll(window.location.hash);
  } else if (hash) {
    handleScroll(hash);
  } else {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
}
