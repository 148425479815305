import React, { Component } from 'react';

import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

export default class ModalSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          autoPlay
          channel={this.props.channel}
          isOpen={this.props.isOpen}
          videoId={this.props.videoId}
          onClose={this.props.closeModal}
        />
      </React.Fragment>
    );
  }
}
