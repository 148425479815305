import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./section.scss";

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section section-cristalia"
          data-image-src=""
          id="cristalia"
        >
          <div className="bg-cristalia cristalia-half">
            <div className="strip"></div>
            <div className="display-table">
              <div className="display-table-cell">
                <Container>
                  <Row className="justify-content-center">
                    <Col className="content-height" md={12} lg={6}></Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
