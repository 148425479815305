import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

export default class KitBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.registers.map((register, key) => (
          <Col xs={9} sm={9} md={9} lg={3} key={key}>
            <Link to={undefined}>
              <div className="text-center kit-box hover-effect cursor__default">
                <img alt={register.imageDescription} src={register.image} className="img-fluid mb-4" width={150} />
                <div className="kit-box__pre">{register.preDescription}</div>
                <h1>{register.title}</h1>

                {
                  register.divider
                    ? <div className="kitbox__border mb-4"></div>
                    : null
                }
                <div className="kit-box__features">
                  <p>{register.description}</p>
                </div>

                <div className="kit-box__footer">
                  {register.footerDescription}
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}
