import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

export default class LaboratoryBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.registers.map((register, key) => (
          <Col md={12} lg={4} key={key}>
            <Link to={undefined}>
              <div className="text-center laboratory-box hover-effect cursor__default">
                <hr className="laboratory-box__line" />
                <img alt={register.imageDescription} src={register.image} className="laboratory-box__img img-fluid" />
                <h3 className="laboratory-box__title">{register.title}</h3>

                <div className="laboratory-box__features">
                  {register.descriptions.map((item, key) => (
                    <p key={key}>{item}</p>
                  ))}
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}
