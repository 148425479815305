import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import "./section.scss";
import ModalSection from "../../components/common/ModalSection";

export default class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this)
    this.closeModal.bind(this)
  }

  callModal = () => {
    this.setState({ isOpen: true })
  };

  closeModal = () => {
    this.setState({ isOpen: false })
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="section section-home"
          data-image-src=""
          id="home"
        >
          <div className="bg-home home-half">
            <div className="display-table">
              <div className="display-table-cell">
                <Container>
                  <Row className="justify-content-center">
                    <Col className="content-height" md={12} lg={6}></Col>
                    <Col md={12} lg={6} className="section-home-description text-white">
                      <p className="pt-3 home-desc">
                        Bem-vindo ao site do<br/>
                        Programa Cristália Saúde.<br/>
                        Nossa missão é estarmos
                      </p>
                      <h1 className="home-title">
                        Sempre<br/>
                        ao seu lado
                      </h1>
                      <p className="mt-2">
                        <Link
                          onClick={this.callModal}
                          className="btn btn-outline-light btn-rounded btn-home-know-more">
                          Saiba mais
                        </Link>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>

          <ModalSection
            channel="youtube"
            videoId="pvIMTsURjr4"
            closeModal={this.closeModal}
            isOpen={this.state.isOpen}
          />
        </section>
      </React.Fragment>
    );
  }
}
