import React from "react";

import Home from "./pages/Home/Home";
import Program from "./pages/Program/Program";
import GrowingHormone from "./pages/GrowingHormone/GrowingHormone";
import GrowingHormoneIndication from "./pages/GrowingHormoneIndication/GrowingHormoneIndication";
import Cristalia from "./pages/Cristalia/Cristalia";
import Applicator from "./pages/Applicator/Applicator";
import VideoCriscy from "./pages/VideoCriscy/VideoCriscy";
import NotFound from "./pages/NotFound/NotFound";

const routes = [
  { path: "/", component: <Home /> },
  { path: "/program", component: <Program /> },
  { path: "/growingHormone", component: <GrowingHormone /> },
  { path: "/cristalia", component: <Cristalia /> },
  { path: "/applicator", component: <Applicator /> },
  { path: "/growingHormoneIndication", component: <GrowingHormoneIndication /> },
  { path: "/videocriscy01", component: <VideoCriscy /> },
  { path: "*", component: <NotFound /> }
];

export default routes;
