import React, { Component } from "react";
import { Col } from "reactstrap";

export default class CrispenBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.items.map((item, key) => (
          <Col lg={item.large ? 6 : 4} key={key} className="crispen-items mt-3">
            <div className="crispen-box hover-effect cursor__default">
              <img
                alt={item.iconDesc}
                src={item.icon}
                className={item.large ? "crispen-box__large" : "crispen-box__small"}
              />
              <h4 className="crispen-box__title" style={{ fontSize: !item.large ? "medium" : undefined }}>{item.title}</h4>
              <span className="crispen-box__description pt-1 text-dark">{item.desc}</span>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}
