import React, { Component } from "react";
import { Col } from "reactstrap";

export default class SymptomsBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.registers.map((register, key) => (
          <Col xs={9} sm={9} md={9} lg={4} key={key}>
            <div className="text-center symptoms-box hover-effect cursor__default">
              <img alt={register.imageDescription} src={register.image} className="img-fluid mb-2" />

              <div className="symptoms-box__footer">
                {register.title}
                {register.footerDescription}
              </div>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}
