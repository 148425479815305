import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./symptoms.scss";

import SectionTitle from "../common/SectionTitle";
import SymptomsBox from "./SymptomsBox";

export default class Symptoms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registers: [
        {
          image: '/assets/images/icons/icone 10.png',
          imageDescription: 'criança abaixo da estatura recomendada',
          title: 'Baixa estatura'
        },
        {
          image: '/assets/images/icons/icone 9.png',
          imageDescription: 'crianças e estruturas químicas',
          title: "Atraso na idade óssea"
        },
        {
          image: '/assets/images/icons/icone 8.png',
          imageDescription: 'criança com lupa observando',
          title: "Redução da velocidade de crescimento ajustada pela idade"
        },
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-white"
          style={{
            paddingTop: '0em',
            paddingBottom: '4em',
            position: 'relative',
            ...this.props.styles,
          }}
        >
          <Container>
            <SectionTitle
              title="Sinais e sintomas"
              styleTitle="section-symptoms-title"
            />
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row className="justify-content-center">
                  <SymptomsBox registers={this.state.registers} />
                </Row>
              </Col>
              <i className="web-desc ref mt-2">
                Ref: Damiani D. As dificuldades diagnósticas nas deficiências de hormônio de crescimento (parte I). Rev Assoc Med Bras. junho de 2001;47(2):101–2.
              </i>
            </Row>
            <Row>
              <Col lg={{ size: 8, offset: 2 }} className="mt-5 text-center">
                <Link to="/growingHormoneIndication" className="btn btn-warning btn-rounded text-white btn-know-more">
                  Indicações de hormônio do crescimento
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
