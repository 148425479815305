import jwtDecode from "jwt-decode";

export function tokenValidate(token) {
  // Valida se o token está dentro do prazo de validade
  if (token) {
    const decoded = jwtDecode(token);
    if (new Date(decoded.exp * 1000) > new Date())
      return true;
    return false;
  }

  return false;
}
