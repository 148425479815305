import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import './aboutus.scss';

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-purple" style={this.props.styles} id="about-us">
          <Container>
            <Row className="vertical-content">
              <Col lg={7}>
                <div>
                  {this.props.sections.map((section, key) => (
                    <React.Fragment key={key}>
                      <h3 className="topic-title">{section.title}</h3>
                      {section.paragraphs.map((item, key) => (
                        <React.Fragment key={key}>
                          <p className="text-white" dangerouslySetInnerHTML={{ __html: item }}></p>
                        </React.Fragment>
                      ))}
                      {section.references?.length > 0 && <i className="text-white">Ref: </i>}
                      {section?.references?.map((item, key) => (
                        <React.Fragment key={key}>
                          <i className="text-white web-desc" dangerouslySetInnerHTML={{ __html: item }}></i>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
