import React, { Component } from "react";

import Section from "./section";
import NavbarPage from "../../components/Navbar/Navbar";
import AboutUs from "../../components/AboutUs/AboutUs";
import Symptoms from "../../components/Symptoms/Symptoms";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/footer";
import Video from "../../components/Video/Video";

import scrollTo from "../../util/scrollTo";

export default class GrowingHormone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "",
      aboutUs: [
        {
          title: "Deficiência do hormônio do crescimento",
          paragraphs: [
            "A definiciência do Hormônio do Crescimento (DGH) ocorre na infância, quando a hipófise (uma glândula que fica na parte inferior do cérebro), produz pouca quantidade de hormônio do crescimento. Após o diagnóstico é fundamental o tratamento para que os níveis deste hormônio sejam normalizados, para o desenvolvimento da criança e aumento na qualidade de vida.",
          ],
          references: [
            "Ranke MB. Short and Long-Term Effects of Growth Hormone in Children and Adolescents With GH Deficiency. Front Endocrinol (Lausanne). 2021 Sep 1;12:720419.",
            "Tanaka T, Hasegawa T, Ozono K, Tanaka H, Kanzaki S, Yokoya S, et al. Effect of Growth Hormone Treatment on Quality of Life in Japanese Children with Growth Hormone Deficiency: An Analysis from a Prospective Observational Study. Clin Pediatr Endocrinol. 2014;23(3):83–92."
          ]
        }
      ]
    };
  }

  componentDidMount() {
    scrollTo();
  }

  render() {
    return (
      <React.Fragment>
        <NavbarPage
          navClass={this.state.navClass}
        />

        <Section />

        <AboutUs
          sections={this.state.aboutUs}
          styles={{paddingBottom: '0em'}}
        />

        {/* Kids image */}
        <section className="section" id="section-gh-kids">
          <div className="bg-gh-kids"></div>
          <div className="container-float-kids"></div>
        </section>

        <Symptoms />

        <Video />

        <Contact />

        <Footer />
      </React.Fragment>
    );
  }
}
