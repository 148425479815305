export default function isOfLegalAge(value) {
   // Obter a data de hoje
   const today = new Date();
    
   // Calcular a diferença de anos
   const age = today.getFullYear() - value.getFullYear();
   
   // Verificar se a pessoa já fez aniversário este ano
   const monthDiff = today.getMonth() - value.getMonth();
   if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < value.getDate())) {
       return age - 1 >= 18;
   }
   
   // Retornar se a idade é maior ou igual a 18 anos
   return age >= 18;
}
