import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import './footer.scss';

import Consent from "../Regulations&Consent/Consent";
import Regulations from "../Regulations&Consent/Regulations";
import FooterLinks from "./footer-links";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col md={12} lg={6} className="programa-cristalia">
                <img alt="Programa Cristália Saúde" className="programa-cristalia-img" src="/assets/images/CRI-logo-Cristalia-Saude-white-01.svg" />
                <div className="text-white mt-4">
                  <h4>Contato Programa Cristália Saúde</h4>
                  <ul className="list-unstyled footer-list">
                    <li>Em caso de dúvidas entre em contato conosco:</li>
                    <li><Link to='tel:08000006565'>0800 000 6565</Link></li>
                    <li><Link target="_blank" to='https://wa.me/5511933312390?text=Olá!%20Tenho%20interesse%20em%20me%20cadastrar%20no%20Programa%20Cristália%20Saúde'><i className="mdi mdi-whatsapp"></i> (11) 93331-2390</Link></li>
                    <li><Link to='mailto:contato@cristaliasaude.com.br'>contato@cristaliasaude.com.br</Link></li>
                  </ul>
                </div>
              </Col>

              <Col md={12} lg={6} className="cristalia">
                <img alt="Cristália" className="cristalia-img" src="/assets/images/Crop_Logo-Cristalia-negativo-01.svg" />
                <div className="text-white mt-4">
                  <Link target="_blank" to="https://www.cristalia.com.br/"><h5 className="text-white">Site Cristália</h5></Link>
                  <ul className="list-unstyled footer-list">
                    <li><Regulations spanTextColor="text-white" /></li>
                    <li><Consent spanTextColor="text-white" /></li>
                    <li>&nbsp;</li>
                  </ul>
                </div>
                <h6 className="text-white">Contato Cristália</h6>
                <div className="sac-informations">
                  SAC&nbsp;
                  <Link to='tel:08007011918'>0800 701 1918</Link>
                  &nbsp;ou&nbsp;
                  <Link to='mailto:sac@cristalia.com.br'>sac@cristalia.com.br</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>

        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
