import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./service.scss";

export default class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-service"
          id="service"
          style={{
            paddingBottom: '2em',
            position: 'relative'
          }}
        >
          <Container fluid={true}>
            <Row>
              <Col>
                <div className="d-flex justify-content-end">
                  <div className="section-service-description">
                    <h1>Sempre ao seu lado</h1>
                    <p>
                      WhatsApp: <Link to='https://wa.me/5511933312390?text=Olá!%20Tenho%20interesse%20em%20me%20cadastrar%20no%20Programa%20Cristália%20Saúde'>(11) 93331-2390</Link><br />
                      Atendimento: <Link to='tel:08000006565'>0800 000 6565</Link>
                    </p>
                    <p>Atendimento gratuito de segunda a sexta-feira das 8h às 20h</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
