import React, { Component } from "react";

import Section from "./section";
import NavbarPage from "../../components/Navbar/Navbar";
import AboutUs from "../../components/AboutUs/AboutUs";
import CallCenter from "../../components/CallCenter/CallCenter";
import Contact from "../../components/Contact/Contact";
import AccreditedPharmacy from "../../components/AccreditedPharmacy/AccreditedPharmacy";
import Footer from "../../components/Footer/footer";

import scrollTo from "../../util/scrollTo";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "",
      aboutUs: [
        {
          title: "Programa Cristália Saúde",
          paragraphs: [
            "O Programa Cristália Saúde oferece suporte e apoio aos familiares e pacientes durante todo o tratamento com o medicamento hormônio do crescimento administrado através de uma caneta aplicadora.",
            "Nossos serviços foram pensados para assegurar o uso correto e seguro do medicamento para que todos se sintam confortáveis durante a utilização e receba toda orientação necessária. Para nós, é importante que a jornada do paciente e familiares seja completa!",
            "Oferecemos um kit inicial com todos os materiais necessários para o manuseio do produto, guia do paciente com as principais informações e acesso a uma equipe de enfermagem especializada e habilitada para informar sobre o manuseio do medicamento conforme prescrição médica, além das melhores práticas de transporte e conservação."
          ]
        },
        {
          title: "Quem pode participar?",
          paragraphs: [
            "O Programa Cristália Saúde é gratuito e válido para todas as pessoas físicas que recebem de seu médico a prescrição do medicamento hormônio do crescimento administrado através de uma caneta aplicadora."
          ]
        }
      ]
    };
  }

  componentDidMount() {
    scrollTo();
  }

  render() {
    return (
      <React.Fragment>
        <NavbarPage
          navClass={this.state.navClass}
        />

        <Section />

        <AboutUs
          sections={this.state.aboutUs}
          styles={{paddingBottom: '0em'}}
        />

        <CallCenter />

        <AccreditedPharmacy />

        <Contact />

        <Footer />
      </React.Fragment>
    );
  }
}
