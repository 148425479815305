import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

class ContactBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.registers.map((register, key) => (
          <Col xs={9} sm={9} md={9} lg={4} key={key}>
            <Link 
              target={register.linkTarget} 
              to={register.linkTo} 
              onClick={register.handleCustom}
            >
              <div className="text-center contact-box hover-effect">
                <img alt={register.imageDescription} src={register.image} className="img-fluid mb-4" width="110" />
                <div className="contact-box__pre">{register.preDescription}</div>
                <h3>{register.title}</h3>

                <div className="contact-box__description">
                  <p>{register.description}</p>
                </div>

                <div className="contact-box__footer">
                  {register.footerDescription}
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ContactBox;
