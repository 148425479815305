import React, { Component } from "react";

import Video from "../../components/Video/Video";
import "./videoCriscy.scss";

export default class VideoCriscy extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="bg-video-criscy">
          <Video autoplay={true} />
        </div>
      </React.Fragment>
    );
  }
}
