import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem 
} from "reactstrap";
import "./indicationsForUse.scss";

import items from "./items.json";

export default class IndicationsForUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: '1',
      items: items
    };
  }

  toggle = (id) => {
    if (this.state.open === id) {
      this.setState({ open: undefined });
    } else {
      this.setState({ open: id });
    }
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-white"
          style={{
            paddingTop: '4em',
            paddingBottom: '4em',
            position: 'relative',
            ...this.props.styles,
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col md={12} lg={9}>
              <Accordion open={this.state.open} toggle={this.toggle}>
                  {this.state.items.map((item, key) => (
                    <AccordionItem key={key}>
                      <AccordionHeader targetId={key}>{item.title}</AccordionHeader>
                      <AccordionBody accordionId={key}>
                        {item.description}
                        <div className="accordion-footer mt-4">
                          {item.footer}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
              </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
