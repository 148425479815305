import React, { Component } from "react";
import { Col } from "reactstrap";

export default class InformartionBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.items.map((item, key) => (
          <Col lg={item.large ? 6 : 4} key={key} className="mt-3">
            <div className="cristalia-information-box hover-effect">
              <i className="dot">
                <img 
                  alt={item.iconDesc} 
                  src={item.icon} 
                  className={"cristalia-information-box__img"} 
                />
              </i>
              <span className="cristalia-information-box__description pt-1 text-white">{item.desc}</span>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}
